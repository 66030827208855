import { ComputeAmountForFrequencyDto, MonthlyPayment, useComputePaymentAmountForFrequency } from '@src/api/compute-api'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { formatDate } from '@src/services/Formatter'
import { EDefaultPaymentPlanIdEnum, EMPTY_ARRAY, EPaymentFrequency } from '@src/types'
import { addMonths } from 'date-fns'
import { useMemo } from 'react'

export type TermPaymentOption = {
  firstPaymentOn?: string | null
  activationDate?: Date | null
  requestedAmount?: number | null
  paymentPlanId?: string | null
  includeInsurance?: boolean
}

const useTermPaymentsTable = (
  creditApp: FilteredCreditApplication | null,
  option?: TermPaymentOption,
): [MonthlyPayment[], boolean] => {
  const dto = useMemo<ComputeAmountForFrequencyDto | null>(() => {
    if (creditApp) {
      return {
        merchantId: creditApp.merchantId,
        paymentPlanId:
          option?.paymentPlanId ?? creditApp.merchantPaymentPlanId ?? EDefaultPaymentPlanIdEnum.RegularDailyInterests,
        interestRate: creditApp.finalDecision.interestRate,
        hasCoapplicant: creditApp.coapplicant !== null,
        stateIso: creditApp.applicant.province,
        paymentFrequency: EPaymentFrequency.Monthly,
        deliveryOn: formatDate(option?.activationDate ?? new Date()),
        firstPaymentOn: option?.firstPaymentOn ?? formatDate(addMonths(new Date(), 1)),
        maxPmtAmount: creditApp.finalDecision.maxPmtAmount,
        amountRequested:
          option?.requestedAmount ?? Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced),
        includeInsurance: option?.includeInsurance ?? false,
      }
    }
    return null
  }, [option, creditApp])
  const [result, isLoading] = useComputePaymentAmountForFrequency(dto)
  const filteredTermAmountPairs = result?.monthlyTerms.filter(
    (ta) => ta.term <= (creditApp?.finalDecision?.maxTermDuration ?? 0),
  )
  return [filteredTermAmountPairs ?? EMPTY_ARRAY, isLoading]
}

export default useTermPaymentsTable
